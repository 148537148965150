import React, { useEffect, useState } from 'react';
import { Dropdown, DropdownProps, Form, Grid } from 'semantic-ui-react';
import payWeekService, { PayWeek } from '../services/payWeekService';
import reportService from '../services/reportService';
import FileSaver from 'file-saver';
import Alert from './Alert';

const MannaEmployeeHoursAndTipsExport = () => {
  const [loaded, setLoaded] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [payWeeks, setPayWeeks] = useState<PayWeek[]>([]);
  const [selectedPayWeekStart, setSelectedPayWeekStart] = useState('');
  const [dropdownLoading, setDropdownLoading] = useState(false);

  useEffect(() => {
    (async function getPayWeeks() {
      setDropdownLoading(true);
      const fetchedPayWeeks = await payWeekService.getPayWeeksForDropdown();
      setPayWeeks(fetchedPayWeeks);
      setSelectedPayWeekStart(fetchedPayWeeks[0].weekStart);
      setDropdownLoading(false);
    })();
  }, []);

  const handleDropdownChange = (
    e: React.SyntheticEvent<HTMLElement>,
    { value }: DropdownProps
  ) => setSelectedPayWeekStart(value as string);

  const runReport = () => {
    if (!selectedPayWeekStart) {
      setErrorMessage('Please select a payroll period.');
      return;
    }
    setLoaded(false);
    const payWeek = payWeeks.find(pp => pp.weekStart === selectedPayWeekStart);

    reportService
      .teamTipExport(payWeek!.weekStart, payWeek!.weekEnd, 'EmpHours')
      .then(result => {
        if (result) {
          const blob = new Blob([result.data], { type: 'octet/stream' });
          FileSaver.saveAs(blob, result.fileName);
        } else {
          console.error('There was an error while downloading the report.');
          setLoaded(false);
        }
      })
      .then(() => {
        setLoaded(true);
      })
      .catch(error => {
        console.log(error);
        setErrorMessage('There was an error processing your request. Please contact support@microcon.com');
        setLoaded(true);
      });
  };

  return (
    <Grid columns={2} padded>
      <Grid.Column>
        <Grid.Row>
          <Form widths='equal'>
            <Form.Field>
              <label>Payroll Period</label>
              <Dropdown
                placeholder='Select pay period'
                fluid
                selection
                loading={dropdownLoading}
                options={payWeeks.map(payWeek => ({
                  key: payWeek.weekStart,
                  text: `${payWeek.weekStart} - ${payWeek.weekEnd}`,
                  value: payWeek.weekStart
                }))}
                onChange={handleDropdownChange}
              />
            </Form.Field>
            <Form.Button loading={!loaded} onClick={runReport}>
              Submit
            </Form.Button>
          </Form>
        </Grid.Row>
        <Grid.Row>
          {errorMessage !== '' && <Alert negative={true} message={errorMessage} />}
        </Grid.Row>
      </Grid.Column>
    </Grid>
  );
}

export default MannaEmployeeHoursAndTipsExport;
