import React, { useState, useEffect } from 'react'
import './App.css'
import { Route, Switch } from 'react-router'
import Layout from './components/Layout'
import Home from './components/Home'
import UberDashboard from './components/UberDashboard'
import Reports from './components/Reports'
import Exports from './components/Exports'
import AggregateConversion from './components/AggregateConversion'
import PayrollExportMenu from './components/PayrollExport'
import Dashboard from './components/Dashboard'
import UberTipDetail from './components/UberTipDetail'
import TipOrderDetails from './components/TipOrderDetail'
import LoginPage from './components/Login'
import ReportDashboard from './components/ReportDashboard'
import Dashboard_rep from './components/Dashboard_rep'
import OrderLookup from './components/OrderLookup'
import { PrivateRoute } from './utils/PrivateRoute'
import { connect } from 'react-redux'
import { ApplicationState } from './store'
import { actionCreators } from './store/User'
import { actionCreators as alertActionCreators } from './store/Alert'
import DiscrepancyReportmenu from './components/DiscrepancyReport'
import { TiprUser, login, logout} from './services/userService'
import { Dispatch, bindActionCreators } from 'redux'
import { history } from './utils/history'
import ga from './utils/ga'
import EmployeeHours from './components/EmployeeHours'
import CateringTipAllocationSummary from './components/CateringTipAllocationSummary'
import MannaTipBatching from './components/MannaTipBatching'
import TrgTipReport from './components/TrgTipReport'
import MannaGLExport from './components/MannaGLExport'
import MannaOnAccountExtract from './components/MannaOnAccountExtract'
import BBCashFileExport from './components/BBCashFileExport'
import TeamTipExport from './components/TeamTipExport'
import MannaEmployeeHoursAndTipsExport from './components/MannaEmployeeHoursAndTipsExport'
import OrderDetailLookup from './components/OrderDetailLookup'
import HarriJobPostings from './components/HarriJobPostings'
import { User } from 'oidc-client'

interface LoginState {
	user: TiprUser | undefined
}

type AppProps = LoginState & typeof actionCreators & typeof alertActionCreators

const App: React.FC<AppProps> = (props: AppProps): JSX.Element => {
	// This runs once when the component starts
	const { clear, loadSavedUser, logout } = props
	const [lastModifiedDate, setLastModifiedDate] = useState<string | null>(null);
	useEffect(
		() => {
			// Clear any messages when page changes.
			history.listen(location => {
				ga.pageView(location.pathname)
				clear()
			})
			// Load user when application first loads if it is in local storage
			loadSavedUser()

			// Function to handle beforeunload event
			const handleBeforeUnload = (event: BeforeUnloadEvent) => { logout(); };

			// Add the event listener for beforeunload
			window.addEventListener('beforeunload', handleBeforeUnload);

			fetch("/api/version/lastModified")
				.then(response => {
					if (!response.ok) {
						throw new Error(`HTTP error! Status: ${response.status}`);
					}
					return response.json();
				})
				.then(data => {
					let truncatedDate = data.split('T')[0];
					setLastModifiedDate(truncatedDate);
				})
				.catch(error => {
					console.log('Fetch error: ', error);
				});



			// Clean up the event listener when the component is unmounted
			return () => { window.removeEventListener('beforeunload', handleBeforeUnload); };
		}, [clear, loadSavedUser, logout]
	) // useEffect

	// Force a check for the user state when the component mounts
	useEffect(() => { if (!props.user) { logout(); } }, [props.user]);

	return (
		// FJF 4/21/23 - Need to get date/time of TIPR.exe.
		<><div className="version">Version: {lastModifiedDate}</div><Layout>
			<Switch>
				<PrivateRoute
					exact
					path='/'
					render={() => (
						<Dashboard_rep title='Hello, welcome to TIPR!'>
							<Home />
						</Dashboard_rep>
					)} />
				<Route path='/login' render={() => <LoginPage />} />
				{/*<PrivateRoute*/}
				{/*	exact*/}
				{/*	path='/uberOrders'*/}
				{/*	render={() => (*/}
				{/*		<Dashboard title='Uber Tips'> <UberDashboard /> </Dashboard>*/}
				{/*	)} />*/}
				{/*<PrivateRoute*/}
				{/*	path='/uberOrders/:id'*/}
				{/*	render={() => (*/}
				{/*		<Dashboard title='Uber Tips'> <UberTipDetail /> </Dashboard>*/}
				{/*	)} />*/}
				<PrivateRoute
					exact
					path='/tipOrders'
					render={() => (
						<Dashboard_rep title='Manna 3PA Tips'> <ReportDashboard /> </Dashboard_rep>
					)} />

				<PrivateRoute
					path='/orderLookup'
					render={() => (
						<Dashboard_rep title='Manna 3PA Order Lookup'> <OrderLookup /> </Dashboard_rep>
					)} />

				{/*<PrivateRoute*/}
				{/*	path='/tipOrders/:id'*/}
				{/*	render={() => (*/}
				{/*		<Dashboard title='3PA Tip Detail'> <TipOrderDetails /> </Dashboard>*/}
				{/*	)} />*/}

				<PrivateRoute
					path='/tipsWorkstation'
					render={() => (
						<Dashboard_rep title='Manna Employee Hours and Tips'> <EmployeeHours /> </Dashboard_rep>
					)} />

				<PrivateRoute
					path='/MannaEmployeeHoursAndTipsExport'
					render={() => (
						<Dashboard_rep title='Manna Emp Hrs And Tips Export (total for pay period)'> <MannaEmployeeHoursAndTipsExport /> </Dashboard_rep>
					)} />

				<PrivateRoute
					path='/TeamTipExport'
					render={() => (
						<Dashboard_rep title={props.user && props.user.companyId === 33 ? 'Mike Mariola Restaurants Labor and Tip Export' : 'Manna Team Tip Export'}>
							<TeamTipExport />
						</Dashboard_rep>
					)} />


				<PrivateRoute
					path='/MannaTipBatching'
					render={() => (
						<Dashboard_rep title='Manna Catering Tips Export'> <MannaTipBatching /> </Dashboard_rep>
					)} />

				<PrivateRoute
					path='/tipsAllocationSummary'
					render={() => (
						<Dashboard_rep title='Manna Catering Tip Allocation Summary'> <CateringTipAllocationSummary /> </Dashboard_rep>
					)} />

				<PrivateRoute
					exact
					path='/orderDetails'
					render={() => (
						<Dashboard_rep title='Manna Cafe Orders'> <OrderDetailLookup /> </Dashboard_rep>
					)} />

				<PrivateRoute
					path='/MannaGLExport'
					render={() => (
						<Dashboard_rep title='Manna 3PA GL Extract'> <MannaGLExport /> </Dashboard_rep>
					)} />

				<PrivateRoute
					path='/MannaOnAccountExtract'
					render={() => (
						<Dashboard_rep title='Manna OnAccount Extract'> <MannaOnAccountExtract /> </Dashboard_rep>
					)} />

				<PrivateRoute
					path='/trgTipReport'
					render={() => (
						<Dashboard_rep title='TRG Tip Report'> <TrgTipReport /> </Dashboard_rep>
					)} />

				<PrivateRoute
					path='/BBCashFileExport'
					render={() => (
						<Dashboard_rep title='Breaking Bread Sales And Cash Export'> <BBCashFileExport /> </Dashboard_rep>
					)} />

				<PrivateRoute
					path='/HarriJobPostings'
					render={() => (
						<Dashboard_rep title='Covelli Harri/BalanceTrak Job Postings'> <HarriJobPostings /> </Dashboard_rep>
					)} />

				{/*FF 1/22/24 - Not sure what any of these are for.*/}
				<PrivateRoute path='/reports' render={() => <Reports />} />
				<PrivateRoute path='/exports' render={() => <Exports />} />
				<PrivateRoute
					path='/aggregateConversion'
					render={() => <AggregateConversion />} />
				<PrivateRoute
					path='/payrollExport'
					render={() => <PayrollExportMenu />} />
				<PrivateRoute
					path='/discrepancyReport'
					render={() => (
						<Dashboard_rep title='Discrepancy Report'> <DiscrepancyReportmenu /> </Dashboard_rep>
					)} />

			</Switch>
		</Layout></>
	)
}

const mapState = (state: ApplicationState) => ({
	user: state.user
})

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators({ ...actionCreators, ...alertActionCreators }, dispatch)

const ConnectedApp = connect(mapState, mapDispatchToProps)(App)

export default ConnectedApp
