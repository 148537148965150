import Axios from 'axios'
import config from '../utils/config'
import authHelper from '../utils/authHelper'
import handleResponseAuthorization from '../utils/responseHelper'
import moment from 'moment'

const baseUrl = config.API_BASE_URL

export interface ReportType {
  id: number
  name: string
}

export interface Discrepancy {
  company: number
  uberBusDate?: Date
  paneraBusDate?: Date
  uberStore: string
  panStore: string
  uberON: string
  paneraON: string
  tips?: number
  orderStatus: string
  batchId?: number
}

export interface DiscrepancySearchCriteria {
  pageNumber?: number
  company: number
}

export interface PayPeriod {
  recid: number
  accountingPayPeriodId: number
  payPeriod: number
  payPeriodStart: Date
  payPeriodEnd: Date
  payPeriodLabel: string
}

export interface PaginatedList<T> {
  pageNumber: number
  totalPages: number
  data: T[]
  hasPreviousPage: boolean
  hasNextPage: boolean
}

const reports: ReportType[] = [
  {
    id: 1,
    name: 'Sample Report'
  },
  {
    id: 2,
    name: 'Tips By Employee'
  },
  {
    id: 3,
    name: 'Payroll Report'
  }
]

const getAvailableReports = (): ReportType[] => reports

const defaultDiscrepancyList: PaginatedList<Discrepancy> = {
  pageNumber: 0,
  totalPages: 0,
  data: [],
  hasPreviousPage: false,
  hasNextPage: false
}

const getAllDiscrepancies = (
  urlParams: number | undefined
): Promise<PaginatedList<Discrepancy>> => {
  return Axios.get<PaginatedList<Discrepancy>>(`${baseUrl}/discrepancy`, {
    headers: authHelper.authHeader()
  })
    .then(response => response)
    .then(result => {
      return result.data
    })
    .catch(error => {
      handleResponseAuthorization(error)
      return defaultDiscrepancyList
    })
}

const runDiscrepancyReport = async (companyId: number) => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const response = await Axios.get(
      `${baseUrl}/export/discrepancy/${companyId}`,
      { headers: authHelper.authHeader() }
    )
    const fileName = response.headers['x-tipr-filename']
    const fileType = response.headers['content-type']
    return { fileName, data: response.data, fileType }
  } catch (error) {
    handleResponseAuthorization(error)
  }
}

const searchDiscrepancy = (
  searchCriteria: DiscrepancySearchCriteria
): Promise<PaginatedList<Discrepancy>> => {
  let url = `${baseUrl}/discrepancy/${searchCriteria.company}`

  if (searchCriteria.pageNumber) {
    url = url + '?pageNumber=' + searchCriteria.pageNumber
  }

  return Axios.get<PaginatedList<Discrepancy>>(url, {
    headers: authHelper.authHeader()
  })
    .then(response => response)
    .then(result => result.data)
    .catch(error => {
      handleResponseAuthorization(error)
      return defaultDiscrepancyList
    })
}

const exportQuery = async (searchCriteria: DiscrepancySearchCriteria) => {
  const urlParams = searchCriteria.company

  const url: string = urlParams
    ? `${baseUrl}/discrepancy/ExportSearch?${urlParams}`
    : `${baseUrl}/discrepancy/ExportSearch`

  try {
    const response = await Axios.get(`${url}`, {
      responseType: 'arraybuffer',
      headers: authHelper.authHeader()
    })
    const fileName = response.headers['x-tipr-filename']
    const fileType = response.headers['content-type']
    return { fileName, data: response.data, fileType }
  } catch (error) {
    handleResponseAuthorization(error)
    return Promise.reject(error)
  }
}

const trgTipReport = async (startDate: Date, endDate: Date) => {
  const url = `${baseUrl}/reports/TrgTipReport?start=${moment(startDate).format(
    'MM/DD/YYYY'
  )}&end=${moment(endDate).format('MM/DD/YYYY')}`

  try {
    const response = await Axios.get(url, {
      responseType: 'arraybuffer',
      headers: authHelper.authHeader()
    })
    const fileName = response.headers['x-tipr-filename']
    const fileType = response.headers['content-type']
    return { fileName, data: response.data, fileType }
  } catch (error) {
    handleResponseAuthorization(error)
    return Promise.reject(error)
  }
}

const mannaGLExport = async (startDate: Date, endDate: Date) => {
  const url = `${baseUrl}/reports/MannaGLExport?start=${moment(
    startDate
  ).format('MM/DD/YYYY')}&end=${moment(endDate).format('MM/DD/YYYY')}`

  try {
    const response = await Axios.get(url, {
      responseType: 'arraybuffer',
      headers: authHelper.authHeader()
    })
    const fileName = response.headers['x-tipr-filename']
    const fileType = response.headers['content-type']
    return { fileName, data: response.data, fileType }
  } catch (error) {
    handleResponseAuthorization(error)
    return Promise.reject(error)
  }
}

const mannaOnAccountExport = async (startDate: Date, endDate: Date) => {
  const url = `${baseUrl}/reports/MannaOnAccountExport?start=${moment(
    startDate
  ).format('MM/DD/YYYY')}&end=${moment(endDate).format('MM/DD/YYYY')}`

  try {
    const response = await Axios.get(url, {
      responseType: 'arraybuffer',
      headers: authHelper.authHeader()
    })
    const fileName = response.headers['x-tipr-filename']
    const fileType = response.headers['content-type']
    return { fileName, data: response.data, fileType }
  } catch (error) {
    handleResponseAuthorization(error)
    return Promise.reject(error)
  }
}

const mannaTimepunchExtract = async (startDate: Date, endDate: Date) => {
  const url = `${baseUrl}/reports/MannaTimepunchExtract?start=${moment(
    startDate
  ).format('MM/DD/YYYY')}&end=${moment(endDate).format('MM/DD/YYYY')}`

  try {
    const response = await Axios.get(url, {
      responseType: 'arraybuffer',
      headers: authHelper.authHeader()
    })
    const fileName = response.headers['x-tipr-filename']
    const fileType = response.headers['content-type']
    return { fileName, data: response.data, fileType }
  } catch (error) {
    handleResponseAuthorization(error)
    return Promise.reject(error)
  }
}

const teamTipExport = async (startDate: string, endDate: string, tipType: string) => {
  const url = `${baseUrl}/reports/TeamTipExport?start=${startDate}&end=${endDate}&tipType=${tipType}`

  try {
    const response = await Axios.get(url, {
      responseType: 'arraybuffer',
      headers: authHelper.authHeader()
    })
    const fileName = response.headers['x-tipr-filename']
    const fileType = response.headers['content-type']
    return { fileName, data: response.data, fileType }
  } catch (error) {
    handleResponseAuthorization(error)
    return Promise.reject(error)
  }
}

const getLastTimePunchExtractStartDate = async () => {
  const url = `${baseUrl}/reports/MannaTimePunchExtractLastStartDate`

  try {
    const response = await Axios.get<Date | null>(url, {
      headers: authHelper.authHeader()
    })

    return response.data
  } catch (error) {
    handleResponseAuthorization(error)
    return Promise.reject(error)
  }
}

const getBBCashFileExport = async (startDate: Date, endDate: Date | null) => {
  const endDateString =
    endDate === null ? '' : `&end=${moment(endDate).format('MM/DD/YYYY')}`
  const url = `${baseUrl}/reports/BBCashFileExport?start=${moment(
    startDate
  ).format('MM/DD/YYYY')}${endDateString}`

  try {
    const response = await Axios.get(url, {
      responseType: 'arraybuffer',
      headers: authHelper.authHeader()
    })
    const fileName = response.headers['x-tipr-filename']
    const fileType = response.headers['content-type']
    return { fileName, data: response.data, fileType }
  } catch (error) {
    handleResponseAuthorization(error)
    return Promise.reject(error)
  }
}

const getPayPeriodsForDropdown = async () => {
  const url = `${baseUrl}/PayPeriod`
  try {
    const response = await Axios.get<PayPeriod[]>(url, {
      responseType: 'json',
      headers: authHelper.authHeader()
    })

    return response.data
  } catch (error) {
    handleResponseAuthorization(error)
    return Promise.reject(error)
  }
}

export default {
  getAvailableReports,
  runDiscrepancyReport,
  getAllDiscrepancies,
  searchDiscrepancy,
  exportQuery,
  trgTipReport,
  mannaGLExport,
  mannaTimepunchExtract,
  teamTipExport,
  mannaOnAccountExport,
  getLastTimePunchExtractStartDate,
  getPayPeriodsForDropdown,
  getBBCashFileExport
}
